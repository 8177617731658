import $ from 'jquery';
import debounce from 'lodash/debounce'
import fitvids from 'fitvids'
import 'what-input';
import './slick';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

// SELECT CUSTOM

var x, i, j, l, ll, selElmnt, a, b, c;
/*look for any elements with the class "custom-select":*/
x = document.getElementsByClassName("custom-select");
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  ll = selElmnt.length;
  /*for each element, create a new DIV that will act as the selected item:*/
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /*for each element, create a new DIV that will contain the option list:*/
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 0; j < ll; j++) {
    /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener("click", function (e) {
      /*when an item is clicked, update the original select box,
      and the selected item:*/
      var y, i, k, s, h, sl, yl;
      s = this.parentNode.parentNode.getElementsByTagName("select")[0];
      sl = s.length;
      h = this.parentNode.previousSibling;
      for (i = 0; i < sl; i++) {
        if (s.options[i].innerHTML == this.innerHTML) {
          s.selectedIndex = i;
          h.innerHTML = this.innerHTML;
          y = this.parentNode.getElementsByClassName("same-as-selected");
          yl = y.length;
          for (k = 0; k < yl; k++) {
            y[k].removeAttribute("class");
          }
          this.setAttribute("class", "same-as-selected");
          break;
        }
      }
      h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function (e) {
    /*when the select box is clicked, close any other select boxes,
    and open/close the current select box:*/
    e.stopPropagation();
    closeAllSelect(this);
    this.nextSibling.classList.toggle("select-hide");
    this.classList.toggle("select-arrow-active");
  });
}

function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x, y, i, xl, yl, arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i)
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);

// ACCORDION

(function ($) {

  var allPanels = $('.accordion > p');

  $('.accordion > dt > label').click(function () {
    allPanels.slideUp();
    if (this.classList.contains("active")) {
        allPanels.slideUp();
        $('.accordion label').removeClass("active");
    }
    else {
    $('.accordion label').removeClass("active");
    $(this).parent().next().slideDown();
    $(this).addClass("active");
    return true;
  }
  });

  


  // Accordion responsive sidebar


  $('.accordion-sidebar > .panel').hide();

  $('.accordion-sidebar > dt > label').click(function () {
    $('.accordion-sidebar label').toggleClass("active");
    $('.panel').slideToggle();
  });

  // Carousel

  $(".regular-carousel").slick({
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null,
    responsive: [
      {
        breakpoint: 450,
        settings: {
          centerMode: true,
          centerPadding: '20px',
          slidesToShow: 1.20,
        }
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          centerPadding: '20px',
          slidesToShow: 2.20,
        }
      },
    ]
  });

  $(".regular-carousel-1").slick({
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null
  });

  $('.regular-carousel-benefit').slick({
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToScroll: 1,
    slidesToShow: 1.13,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
          slidesToShow: 3,
        }
      }
    ]
  })

  $('.regular-carousel-3').slick({
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToScroll: 1,
    slidesToShow: 1.13,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
          slidesToShow: 3,
        }
      }
    ]
  })

  $(".regular-carousel-4").slick({
    dots: true,
    infinite: true,
    centerMode: true,
    centerPadding: '10px',
    slidesToShow: 1.05,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 460,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 766,
        settings: {
          centerMode: false,
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1239,
        settings: {
          centerMode: false,
          slidesToShow: 4,
        }
      }
    ]
  });  

  $(".regular-carousel-5").slick({
    dots: true,
    arrows: false,
    infinite: false,
    slidesToScroll: 1,
    prevArrow: null,
    nextArrow: null,
    mobileFirst: true,
    centerMode: true,
    centerPadding: '20px',
    slidesToShow: 1.15,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          centerMode: false,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          centerMode: false
        }
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          centerMode: false,
        }
      }
    ]
  });

  const eventCarouselParams = {
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToScroll: 1,
    slidesToShow: 1.13,
    centerMode: true,
    centerPadding: '0px',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1024,
        settings: 'unslick'
      },
    ]
  }
  const eventCarouselParams2 = {
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    slidesToScroll: 1,
    slidesToShow: 1.13,
    centerMode: true,
    centerPadding: '25px',
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          slidesToShow: 4
        }
      }
    ]
  }
  const $eventCarousel = $(".regular-carousel-event").slick(eventCarouselParams);

  // When resized, check to reinitialized the slider
  $(window).on('resize', debounce(() => {
    $eventCarousel.not('.slick-initialized').slick(eventCarouselParams)
  }, 250))

  const $eventCarousel2 = $(".regular-carousel-curated").slick(eventCarouselParams2);

  // When resized, check to reinitialized the slider
  $(window).on('resize', debounce(() => {
    $eventCarousel2.not('.slick-initialized').slick(eventCarouselParams2)
  }, 250))


  if (document.URL.includes("event-detail.html")) {
    $(".menu-socal").addClass("bg-light");
  }
  else if (document.URL.includes("home-photo.html")) {
    $(".menu-socal .brand img").attr("src", "../assets/img/scg-logo-white@2x.png");
  }

  $('.moreless-button').click(function() {
    $('.more-text').slideToggle();
    $('.more-dots').fadeToggle();
    if ($('.moreless-button').html() === "Read more") {
      $(this).html("Read less");
      console.log($('.moreless-button').text())
    } else {
      $(this).html("Read more");
    }
  });

  
  $(window).scroll(function() {
    if ($(this).scrollTop() > 0) {
        $(".menu-socal").addClass("m-white");
    } else {
      
      $(".menu-socal").removeClass("m-white");
    }
});

var mobileScreen = window.matchMedia("(max-width: 767px)")
var signinShown = false;

$(window).on('scroll', function() {
  if (!signinShown && $(this).scrollTop() >= $('body').height() / 3) {
    $(".module-signin").addClass('active');
    signinShown = true;
  }
});

// if (mobileScreen.matches) {
//     $(".module-signin").fadeIn();
// } else {
//   $(window).scroll(function() {
//     if ($(this).scrollTop() >= $('body').height() / 3) {
//       $(".module-signin").fadeIn();
//     }
//   });
// }

$(".module-signin .icon-close").on("click", function (){
  $(".module-signin").removeClass('active');
})


const list = document.querySelectorAll('.list-m');

function accordion(e){
    e.stopPropagation(); 
    if(this.classList.contains('active')){
        this.classList.remove('active');
    }
    else if(this.parentElement.parentElement.classList.contains('active')){
        this.classList.add('active');
    }
    else{
        for(i=0; i < list.length; i++){
          list[i].classList.remove('active');
        }
            this.classList.add('active');
        }
}
for(i = 0; i < list.length; i++ ){
    list[i].addEventListener('click', accordion);
}

$(".hamburger-menu").on("click", function (){
    $(".container-menu-m").slideToggle();
    $(".hamburger-menu .icon").toggleClass("close-icon");
      $(".menu-socal").toggleClass("m-white-2");
})

$("#notice-copy a.expanded").on("click", function (){
  $(this).toggleClass("open");
  $(".menu-sidebar-m").slideToggle();
})

// Fitvids
fitvids('.entry-content')

$("#menu-sidebar-left ul li a").on("click", function (){
    $("#menu-sidebar-left ul li a").removeClass("active");
    $(this).addClass("active");
})

$("#pricing .total").hide();

$('#pricing .calculateDues').on("click", function (){
  $("#pricing .total").fadeIn();
})

$('.search-toggle').on('click', function (e) {
  e.preventDefault()
  $('body').toggleClass('search-modal--is-showing')
  if ($('body').hasClass('search-modal--is-showing')) {
    $('.search-modal input[type="text"]').focus()
  }
})

})(jQuery);


// (function($) {
//   $(document).ready(function(){
//     // Target your .container, .wrapper, .post, etc.
//     // $(".story-content").fitVids();
//   });
//   })(jQuery);
